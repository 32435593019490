'use strict'



routes.checkout = function(id, action) {
  
  
    mount('loading');
         recipeService.fetchRecommendedRecipes()
        .then(function(recipes) {
          var recommendedRecipies={}
             for (var key in recipes ){
               recommendedRecipies[key]=recipes[key];
             }
       var getDeliveryDates=function(){
   var monthName =   ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
    var aryDates = [];

var daysToAdd=7;
        var currentDate = new Date();
        var i=0;
   if(currentDate.getHours()>=15)
        {
        i++;
        daysToAdd++;
        }



    for (; i < daysToAdd; i++) {
        var currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + i);

        var disp_date=currentDate.getDate() + " " + monthName[currentDate.getMonth()] + " " + currentDate.getFullYear();
        if(i==1)
        {
         disp_date="Tomorrow"   
        }
         if(i==0)
        {
         disp_date="Today"   
        }
console.log("currentdata",currentDate);
// if(currentDate.getDate()!=10){
            aryDates.push({
                "display_date": disp_date,
                "date": currentDate.getTime()

            });
// }
    }

    return aryDates;
    
}


             
        var  deliveryDates=getDeliveryDates(); 
        
 
          
            mount('checkout', { recipes: recommendedRecipies,delivery_dates:deliveryDates,timeslot:timeslot});
         });

    
}